import React, { useState } from "react";
import SkinSlider from "./SkinSlider";

const ProfileCard = () => {
    const getPorfileList = () => {
        let profile_list = [];

        let profile_data = [
            {
                dt: "ユーザーネーム",
                dd: "抜けは通報",
                span: "col-span-2",
                fs: "text-2xl",
            },
            {
                dt: "身長",
                dd: "5′5″",
                span: "col-span-1",
                fs: "text-lg",
            },
            {
                dt: "体重",
                dd: "176lbs",
                span: "col-span-1",
                fs: "text-lg",
            },
            {
                dt: "Twitter",
                dd: <a href="https://x.com/nuke_tu" className="text-lime-600 decoration-lime-600">@nuke_tu</a>,
                span: "col-span-1",
                fs: "text-base",
            },
            {
                dt: "XP",
                dd: <div className="mt-1 w-32 sm:w-28 h-6 border-y-4 border-x-2 border-white bg-gradient-to-r from-green-700 to-lime-500"></div>,
                span: "col-span-2 sm:col-span-1",
                fs: "text-lg",
            }
        ];

        for (let i in profile_data) {
            profile_list.push(
                <div className={profile_data[i].span}>
                    <span className="text-profilelist-dt">{profile_data[i].dt}</span><br />
                    <span className={profile_data[i].fs}>{profile_data[i].dd}</span>
                </div>
            )
        }
        return profile_list;
    }
    let profile_list = getPorfileList()

    const [btnToggle, setBtnToggle] = useState(false);

    return (
        <div className="card px-8 pt-8 sm:pr-12 sm:pt-10">
            <div className="md:flex mx-auto">
                {/* Image */}
                <div className="pc-border w-fit bg-profilecard-img">
                    <SkinSlider></SkinSlider>
                </div>
                {/* Profile list */}
                <div className="grid grid-cols-2 gap-3 sm:gap-6 mt-5 ml-5 sm:mt-0 items-center">
                    {profile_list}
                </div>
            </div>
            <div className="w-48 text-center mx-auto my-5 sm:my-3 py-2 bg-black text-white text-sm rounded-lg border-4 border-white hover:hover-btn-border-color" onClick={() => {setBtnToggle(true)}}>{!btnToggle ? "自己紹介を表示する" : <span>キルク0好きです。<br />抜けても通報しません。</span>}</div>
        </div>
    );
}

export default ProfileCard;