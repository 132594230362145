import React from "react"

class Header extends React.Component {
    render(){
        return (
            <div className="text-center mt-10">
                <h1 className="font-bold text-2xl sm:text-4xl hachi-maru-pop">抜けは通報のホームページ</h1>
            </div>
        )
    }
}

export default Header