import React from "react";

const Footer = () => {
    return (
        <div className="bg-footer text-gray-200 text-center w-full mt-5 py-2">
            <span>Copyright © 抜けは通報</span>
        </div>
    )
}

export default Footer;