import { Splide, SplideSlide } from "@splidejs/react-splide";
import '@splidejs/splide/css';

export const Slider = () => {
    return (
            <Splide
                aria-label=""
                options={{
                    autoplay: true, // 自動再生を有効
                    interval: 3000, // 自動再生の間隔を3秒に設定
                    type: "loop",
                    width: 200,
                    gap: "1rem",
                    arrows: false
                }}
            >
                <SplideSlide>
                    <img className="skin-img" src="/img/skin1.png" alt="skin1" />
                </SplideSlide>
                <SplideSlide>
                    <img className="skin-img" src="/img/skin2.png" alt="skin2" />
                </SplideSlide>
                <SplideSlide>
                    <img className="skin-img" src="/img/skin3.png" alt="skin3" />
                </SplideSlide>
            </Splide>
            );
};

export default Slider;