import React from "react";
import ProfileCard from "./components/ProfileCard";
import Header from "./components/Header";
import ImageCard from "./components/ImageCard";
import Footer from "./components/Footer";

const App = () => {
    return (
        <div className="App">
            <Header></Header>
            <ProfileCard></ProfileCard>
            <ImageCard></ImageCard>
            <Footer></Footer>
        </div>
    );
}

export default App;
