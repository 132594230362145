import React from "react";

const ImageCard = () => {
    return (
        <div className="text-center">
            <h2 className="hachi-maru-pop text-2xl">イラスト集</h2>
            <div className="w-fit mx-auto sm:grid sm:grid-cols-2">
                {(
                    () => {
                        const nuke2_images = [];
                        const ni_len = 4;
                        const illustrators = ['sakiga_ke', 'sakiga_ke', 'sakiga_ke', 'iooooori39'];
                        let illustrator = null
                        for (let i=1;i<=ni_len;i++) {
                            illustrator = illustrators[i-1]
                            nuke2_images.push(
                                <div className="nuke2-images">
                                    <img src={"/img/nuke2-images/"+i+".jpg"} alt="nuke2-images..." />
                                    <span className="block mt-2 hachi-maru-pop">作者 <a href={"https://x.com/"+illustrator} className="text-lime-800 decoration-lime-800">@{illustrator}</a></span>
                                </div>
                            );
                        }
                        return nuke2_images;
                    })()
                }
            </div>
        </div>
    )
}

export default ImageCard